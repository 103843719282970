
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import InputLinkVerify from "@/shared/components/Forms/InputLinkVerify.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import Step3LinksRedirectURL from "@/web/views/Developer/AddApp/Step3Links/Step3LinksRedirectURL.vue";
import { useVModel } from "vue-composable";
import { CreateApplication_createApplication_ApplicationWithSecret } from "@/api/developer/__generated__/CreateApplication";

export default defineComponent({
  emits: ["update-redirect-url", "remove-redirect-url"],
  props: {
    value: {
      type: Object as PropType<CreateApplication_createApplication_ApplicationWithSecret>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const localFormStateModel = useVModel(props, "value");

    return {
      t,
      localFormStateModel,
    };
  },
  methods: {
    makeRequiredRule,
  },
  components: {
    InputLinkVerify,
    Step3LinksRedirectURL,
  },
});
