
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { useRouter } from "vue-router";
import { ApplicationType } from "@/web/views/Developer/Configure/ConfigureApp.vue";
import Step1DetailsBody from "@/web/views/Developer/AddApp/Step1Details/Step1DetailsBody.vue";
import routeNames from "@/web/router/routeNames";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { authorizedDomainFormatter } from "@/developer/utils/appList";
import { getImageFromUrl } from "@/shared/utils/fileUtils";
import { useArrayForms } from "@/shared/composables/form/useArrayForms";
import { DetailsType } from "@/web/views/Developer/AddApp/AddApp.vue";
import { message } from "ant-design-vue";
import isEmpty from "lodash/isEmpty";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";

export default defineComponent({
  components: {
    FormGroup,
    Step1DetailsBody,
  },
  props: {
    initialFormState: {
      type: Object as PropType<ApplicationType>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const avatarFile = ref();

    /**
     * Get channel list to be used on email and domain list dropdown
     */
    const { channelList, loading: channelListLoading } =
      useCommunicationChannelList({ isVerified: true });

    const { applicationState, updateAppListLoading, handleUpdateApplication } =
      useDeveloperApplications();

    const authorizedDomain = useArrayForms<DetailsType>({
      domainName: null,
    });

    // Form state default value
    const localFormState = reactive({
      logo: props?.initialFormState?.logo400 || "",
      logo400: props?.initialFormState?.logo400 || "",
      name: props?.initialFormState?.name || null,
      authorizedDomains: authorizedDomain.formCollection,
      includeSubdomain: props?.initialFormState?.includeSubdomain || false,
      supportEmail: props?.initialFormState?.email || null,
    });

    // Wrap in refs to avoid eslint error
    const domain = ref(props?.initialFormState?.domain);
    if (domain.value && !isEmpty(domain.value)) {
      // Assign values to Authorized Domains
      localFormState.authorizedDomains = domain.value;
    }

    // Add Domain Item
    const onDomainItemsUpdate = () => {
      /**
       * We just need to add 1 item that doesn't need to be checked
       * whether we add or subtract from array, so call addFormItem
       */
      authorizedDomain.addFormItem();
    };

    // Remove Domain Item
    const handleRemoveDomain = (index: number) => {
      authorizedDomain.deleteFormItem(index);
    };

    /**
     * Format Image from URL to be passed on endpoint
     * TODO: Add onFileChanged on File Uploader
     */
    const handleAvatarChange = async (url) => {
      const file = await getImageFromUrl(url);
      avatarFile.value = file;
    };

    // On Continue Button
    const handleSubmit = async () => {
      try {
        await handleUpdateApplication({
          input: {
            id: applicationState.id,
            name: localFormState.name,
            authorizedDomainChannelIds: authorizedDomainFormatter(
              localFormState?.authorizedDomains
            ),
            includeSubdomain: localFormState.includeSubdomain,
            supportEmailChannelId: localFormState.supportEmail,
          },
          logo: avatarFile.value,
        });

        message.info(t("App Details Successfully Updated."));
      } catch (error) {
        console.log("Error on Configure Details", error);
      }
    };

    // On Back Button
    const handleBack = () => {
      // go back to dashboard
      router.push({ name: routeNames.applications });
    };

    return {
      t,
      router,
      localFormState,
      channelList,
      channelListLoading,
      loading: computed(() => updateAppListLoading.value),
      onDomainItemsUpdate,
      handleRemoveDomain,
      handleSubmit,
      handleBack,
      handleAvatarChange,
    };
  },
});
