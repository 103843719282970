import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import {
  CommunicationChannels_communicationChannels_CommunicationChannelResults_results,
  CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel,
  CommunicationChannels_communicationChannels_CommunicationChannelResults_results_EmailChannel,
} from "@/api/communicationChannels/__generated__/CommunicationChannels";

/**
 * Converts channel results to usable a-select options
 */
export const channelListToSelectOption = (
  channelList?:
    | (CommunicationChannels_communicationChannels_CommunicationChannelResults_results | null)[]
    | null,
  filterType?:
    | CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["__typename"]
    | CommunicationChannels_communicationChannels_CommunicationChannelResults_results_EmailChannel["__typename"]
    | null
) => {
  let list = channelList;

  // Filter list by this type if not null
  if (filterType) {
    list = filter(list, (channel) => channel?.__typename === filterType);
  }

  if (list === undefined || list === null || isEmpty(list)) {
    return [];
  }

  // re-map channel list
  return list.map((domain) => ({
    label: domain?.value,
    value: domain?.id,
  }));
};
