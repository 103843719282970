
import { defineComponent, onMounted, PropType } from "vue";
import ArrowIcon from "@/assets/icons/arrow.svg";
import CustomListItem, {
  CustomListItemType,
} from "@/shared/components/CustomList/CustomListItem.vue";

export default defineComponent({
  components: { CustomListItem },
  props: {
    list: {
      type: Array as () => PropType<CustomListItemType[]>,
    },
    /**
     * If set to true, this will set margin to negative value
     * Note: that this negative value is for card body only
     */
    autoMargin: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    /**
     * Show usage error if expanded body is not defined
     * This can't have a default body because designs doesn't have a single common body design
     */
    onMounted(() => {
      if (props.expandable && !slots["expanded-body"]) {
        console.error(
          "Custom List has expandable props but expanded-body slot is undefined",
          {
            props,
            slots,
          }
        );
      }
    });

    return {
      ArrowIcon,
    };
  },
});
