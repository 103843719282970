
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { copyText } from "vue3-clipboard";
import { message } from "ant-design-vue";
import ConfirmOTPModal from "@/shared/components/Modals/ConfirmOTPModal.vue";
import { ApplicationType } from "@/web/views/Developer/Configure/ConfigureApp.vue";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { useAccount } from "@/shared/composables/Profile/useAccount";

export enum ConfigureAPIType {
  APP_ID = "APP_ID",
  APP_SECRET_KEY = "APP_SECRET_KEY",
  NOTIFICATION_SECRET_KEY = "NOTIFICATION_SECRET_KEY",
}

export default defineComponent({
  components: {
    FormGroup,
    InputText,
    ConfirmOTPModal,
  },
  props: {
    initialFormState: {
      type: Object as PropType<ApplicationType>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const isAppSecretKeyShown = ref(false);
    const isNotificationSecretKeyShown = ref(false);
    const confirmOtpModalRef = ref();
    const { isExternalRegistration } = useAccount();

    const {
      applicationState,
      updateClientSecretLoading,
      handleUpdateClientSecret,
      application,
      applicationLoading,
    } = useDeveloperApplications();

    const localFormState = reactive({
      appId: props.initialFormState?.clientId,
      appSecretKey: props.initialFormState?.clientSecret,
      notificationSecret: props.initialFormState?.notificationSecret,
    });

    const toggleShowAppSecretkey = () => {
      isAppSecretKeyShown.value = !isAppSecretKeyShown.value;
    };

    const toggleShowNotificationSecretKey = () => {
      isNotificationSecretKeyShown.value = !isNotificationSecretKeyShown.value;
    };

    const toggleHideShowValue = (apiType: ConfigureAPIType) => {
      if (apiType === ConfigureAPIType.APP_SECRET_KEY) {
        toggleShowAppSecretkey();
      }
      if (apiType === ConfigureAPIType.NOTIFICATION_SECRET_KEY) {
        toggleShowNotificationSecretKey();
      }
    };

    const copy = (apiField) => {
      copyText(apiField.value, undefined, (error) => {
        if (error) {
          console.log(error);
          message.info(t(`Error copying to clipboard.`));
        } else {
          message.info(
            t(`{keyLabel} has been copied to Clipboard.`, {
              keyLabel: apiField.label,
            })
          );
        }
      });

      // hide key again after copying to clipboard
      toggleHideShowValue(apiField.apiType);
    };

    const handleRegenerate = async (otpCode) => {
      try {
        await handleUpdateClientSecret({
          id: applicationState.id,
          otpCode,
        });

        // close Modal
        confirmOtpModalRef.value?.handleCloseModal();

        message.info(t("App Secret Key Successfully Generated."));

        /**
         * Set newly generated client secret key to App Secret Key field
         * Second array item is the secret key field, see apiFieldsArray
         */
        apiFieldsArray.value[1].value = application.value?.clientSecret;
      } catch (error) {
        console.log("Error on Configure API", error);
      }
    };

    // API Fields
    const apiFieldsArray = computed(() => [
      {
        label: t("App ID"),
        placeholder: t("App ID"),
        name: "appId",
        value: localFormState.appId,
        apiType: ConfigureAPIType.APP_ID,
      },
      {
        label: t("App Secret Key"),
        placeholder: t("App Secret Key"),
        name: "appSecretKey",
        value: localFormState.appSecretKey,
        isValueMasked: !isAppSecretKeyShown.value,
        apiType: ConfigureAPIType.APP_SECRET_KEY,
      },
      {
        label: t("Notification Secret Key"),
        placeholder: t("Notification Secret Key"),
        name: "notificationSecret",
        value: localFormState.notificationSecret,
        isValueMasked: !isNotificationSecretKeyShown.value,
        apiType: ConfigureAPIType.NOTIFICATION_SECRET_KEY,
      },
    ]);

    return {
      t,
      localFormState,
      loading: computed(
        () => updateClientSecretLoading.value || applicationLoading.value
      ),
      isAppSecretKeyShown,
      toggleHideShowValue,
      ConfigureAPIType,
      copy,
      handleRegenerate,
      apiFieldsArray,
      confirmOtpModalRef,
      isExternalRegistration,
    };
  },
});
