import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_avatar = _resolveComponent("a-avatar")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        class: "platform-icon platform-icon--loading",
        active: "",
        paragraph: false,
        title: false,
        avatar: { shape: 'circle' }
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.getPlatformIcon(_ctx.name) || _ctx.logo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: _normalizeClass(["platform-icon", _ctx.$attrs.class]),
              src: _ctx.getPlatformIcon(_ctx.name) || _ctx.logo,
              alt: _ctx.name
            }, null, 10, _hoisted_1))
          : (_ctx.fallbackInitialIcon)
            ? (_openBlock(), _createBlock(_component_a_avatar, {
                key: 1,
                class: _normalizeClass(["platform-icon platform-icon__text", _ctx.$attrs.class])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getFirsts(_ctx.name)), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
      ], 64))
}