import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    title: _ctx.t('App removing'),
    body: _ctx.t('Are you sure you want to remove this app?'),
    type: _ctx.ConfirmModalTypes.danger,
    onOk: _ctx.handleRemoveApp
  }, {
    trigger: _withCtx(({ open }) => [
      _createVNode(_component_a_button, _mergeProps({
        size: "small",
        disabled: _ctx.disabled || _ctx.applicationLoading,
        loading: _ctx.loading || _ctx.deleteApplicationLoading,
        onClick: open,
        danger: ""
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Remove App")), 1)
        ]),
        _: 2
      }, 1040, ["disabled", "loading", "onClick"])
    ]),
    _: 1
  }, 8, ["title", "body", "type", "onOk"]))
}