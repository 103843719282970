import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-list-item flex items-center", [_ctx.bordered && 'card-border-bottom']])
  }, [
    _createVNode(_component_a_space, {
      size: 8,
      class: "flex-1"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "icon", { details: _ctx.details }, () => [
          _createVNode(_component_PlatformIcon, {
            name: _ctx.details.name,
            logo: _ctx.details.logo
          }, null, 8, ["name", "logo"])
        ]),
        _createVNode(_component_a_row, {
          gutter: 8,
          class: "custom-list-item__header flex items-start"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { class: "custom-list-item__header-col" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_typography_paragraph, { class: "custom-list-item__header-col-value mr-2" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.details.value), 1)
                    ]),
                    _: 1
                  }),
                  (!_ctx.isEmpty(_ctx.details.statuses))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.details.statuses, (status) => {
                        return (_openBlock(), _createBlock(_component_a_alert, {
                          banner: "",
                          key: status.type + status.value,
                          message: status.value,
                          type: status.type,
                          "show-icon": false
                        }, null, 8, ["message", "type"]))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.details.description)
                  ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                      key: 0,
                      class: "custom-list-item__header-col-description small",
                      type: _ctx.details.descriptionType
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.details.description), 1)
                      ]),
                      _: 1
                    }, 8, ["type"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 3
    })
  ], 2))
}