import gql from "graphql-tag";

export const deleteApplicationGql = gql`
  mutation DeleteApplication($input: IdInput!) {
    deleteApplication(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
