
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import DashboardEmptyList from "./DashboardEmptyList.vue";
import DashboardAppList from "./DashboardAppList.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { CurrentStepType } from "@/developer/utils/constants";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { appListFormatter } from "@/developer/utils/appList";
import { ApplicationType } from "@/web/views/Developer/Configure/ConfigureApp.vue";

const emptyEditForm: ApplicationType = {
  id: "",
  clientId: "",
  user: null,
  redirectUris: null,
  name: "",
  created: "",
  updated: "",
  logo40: null,
  logo80: null,
  logo400: null,
  authorizedDomains: [],
  includeSubdomain: false,
  supportEmail: null,
  pendingStep: null,
  category: "",
  isPublic: null,
  homepageUri: "",
  tosUri: "",
  privacyPolicyUri: "",
  clientSecret: "",
  email: null,
  domain: [{ domainName: null, uuid: "" }],
  redirect: [{ urlRedirect: null, uuid: "" }],
};

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const {
      applicationState,
      appList,
      loading,
      handleRefetchDeveloperAppList,
    } = useDeveloperApplications();

    const handleAddNewOrContinue = (details) => {
      let step = CurrentStepType.DETAILS; // DETAILS is the default step for New App
      if (details) {
        /**
         * if details is not empty, it means that the user is continuing setup,
         * also assign continue step for automatic redirect
         */
        Object.assign(applicationState, details);
        if (details.continueStep) step = details.continueStep;
      } else {
        // clear application state when adding new app
        Object.assign(applicationState, emptyEditForm);
      }

      router.push({
        name: routeNames.applicationsAdd,
        params: { step },
      });
    };

    /**
     * Go to configure page
     */
    const handleConfigure = async (details) => {
      router.push({
        name: routeNames.applicationsConfig,
        params: {
          app_id: details.id,
        },
      });
    };

    return {
      t,
      appList,
      loading,
      handleRefetchDeveloperAppList,
      parsedAppList: computed(() => appListFormatter(appList.value)),
      handleAddNewOrContinue,
      handleConfigure,
    };
  },
  methods: { isEmpty },
  components: { PageLayout, DashboardEmptyList, DashboardAppList },
});
