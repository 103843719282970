import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formArrayModel, (domain, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "authorized-domain-array flex justify-between items-center",
      key: index
    }, [
      _createVNode(_component_InputDropdown, {
        placeholder: "Select domain from communication channels",
        value: domain.domainName,
        "onUpdate:value": ($event: any) => ((domain.domainName) = $event),
        name: ['authorizedDomains', index, 'domainName'],
        options: _ctx.domainOptions,
        disabled: _ctx.disabled,
        rules: [_ctx.makeRequiredRule(_ctx.t('Domain is required'))],
        validateTrigger: "change"
      }, null, 8, ["value", "onUpdate:value", "name", "options", "disabled", "rules"]),
      (index > 0)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            class: "ml-2 authorized-domain-array__remove-button",
            onClick: ($event: any) => (_ctx.$emit('remove-domain', index)),
            danger: "",
            disabled: _ctx.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Remove")), 1)
            ]),
            _: 2
          }, 1032, ["onClick", "disabled"]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}