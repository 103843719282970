import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "configure-api mt-6" }
const _hoisted_2 = { class: "flex-1 mr-2" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmOTPModal = _resolveComponent("ConfirmOTPModal")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormGroup, {
      class: "configure-api-form",
      loading: _ctx.loading,
      model: _ctx.localFormState
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiFieldsArray, (apiField) => {
          return (_openBlock(), _createElementBlock("div", {
            key: apiField.apiType,
            class: "flex items-center"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_InputText, {
                value: apiField.value,
                "onUpdate:value": ($event: any) => ((apiField.value) = $event),
                label: apiField?.label,
                type: apiField?.isValueMasked ? 'password' : 'text',
                name: apiField?.name,
                placeholder: apiField?.placeholder,
                disabled: ""
              }, null, 8, ["value", "onUpdate:value", "label", "type", "name", "placeholder"])
            ]),
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (apiField?.isValueMasked)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      class: "rounded px-3 mr-2 configure-api__button",
                      onClick: () => _ctx.toggleHideShowValue(apiField.apiType)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Show")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_a_button, {
                        class: "rounded px-3 mr-2 configure-api__button",
                        onClick: ($event: any) => (_ctx.copy(apiField))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Copy")), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_ConfirmOTPModal, {
                        ref_for: true,
                        ref: "confirmOtpModalRef",
                        title: _ctx.t('Regenerate App Secret key'),
                        body: 
                _ctx.isExternalRegistration
                  ? _ctx.t('Are you sure you want to regenerate the App Secret Key?')
                  : _ctx.t(
                      'To regenerate the App Secret Key, please confirm by entering the six digit code from Authenticator app'
                    )
              ,
                        "confirm-text": _ctx.t('Yes, regenerate'),
                        loading: _ctx.loading,
                        "hide-o-t-p-input": _ctx.isExternalRegistration,
                        onConfirm: _ctx.handleRegenerate
                      }, {
                        trigger: _withCtx(({ open }) => [
                          (apiField.apiType === _ctx.ConfigureAPIType.APP_SECRET_KEY)
                            ? (_openBlock(), _createBlock(_component_a_button, {
                                key: 0,
                                class: "rounded px-3 configure-api__button",
                                onClick: open
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Regenerate")), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["title", "body", "confirm-text", "loading", "hide-o-t-p-input", "onConfirm"])
                    ]))
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "model"])
  ]))
}