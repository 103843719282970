import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step-3-links-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLinkVerify = _resolveComponent("InputLinkVerify")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_Step3LinksRedirectURL = _resolveComponent("Step3LinksRedirectURL")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputLinkVerify, {
      label: _ctx.t('Main page link'),
      name: "homepageUri",
      placeholder: "example.com",
      value: _ctx.localFormStateModel.homepageUri,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormStateModel.homepageUri) = $event)),
      showVerificationStatus: ""
    }, null, 8, ["label", "value"]),
    _createVNode(_component_InputLinkVerify, {
      label: _ctx.t('Terms of Service link'),
      name: "tosUri",
      placeholder: "terms-service-example.com",
      value: _ctx.localFormStateModel.tosUri,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormStateModel.tosUri) = $event)),
      showVerificationStatus: ""
    }, null, 8, ["label", "value"]),
    _createVNode(_component_InputLinkVerify, {
      label: _ctx.t('Privacy Policy link'),
      name: "privacyPolicyUri",
      placeholder: "privacy-policy-example.com",
      value: _ctx.localFormStateModel.privacyPolicyUri,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localFormStateModel.privacyPolicyUri) = $event)),
      showVerificationStatus: ""
    }, null, 8, ["label", "value"]),
    _createVNode(_component_a_typography_paragraph, { class: "m-0 font-normal" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("URLs list of redirects")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "my-2 m-0 font-normal lighter small" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("(from authorized domains)")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Step3LinksRedirectURL, {
      "form-array": _ctx.localFormStateModel.redirectUris,
      "onUpdate:form-array": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localFormStateModel.redirectUris) = $event)),
      onRemoveUrl: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('remove-redirect-url', $event))),
      disabled: _ctx.loading
    }, null, 8, ["form-array", "disabled"]),
    _createVNode(_component_a_button, {
      class: "mt-2",
      disabled: _ctx.loading,
      size: "small",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update-redirect-url')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("+ Add one more")), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}