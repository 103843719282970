
import { defineComponent, ref } from "vue";
import PageHeader from "./PageHeader.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const collapsed = ref(false);

    return {
      collapsed,
    };
  },
  components: { PageHeader },
});
