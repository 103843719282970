
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import InputDropdown, {
  DropdownOption,
} from "@/shared/components/Forms/InputDropdown.vue";
import { useVModel } from "vue-composable";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
export type DomainType = {
  domainName: string | null;
}[];

export default defineComponent({
  emits: ["remove-domain"],
  props: {
    formArray: {
      type: Object as PropType<DomainType[]>,
    },
    domainOptions: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const formArrayModel = useVModel(props, "formArray");

    return {
      t,
      formArrayModel,
    };
  },
  methods: { makeRequiredRule },
  components: {
    InputDropdown,
  },
});
