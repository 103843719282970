import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomListItem = _resolveComponent("CustomListItem")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!

  return (_openBlock(), _createBlock(_component_a_collapse, {
    class: _normalizeClass(["custom-list", [_ctx.autoMargin && 'auto-margin']]),
    expandIconPosition: "right",
    bordered: true,
    accordion: ""
  }, {
    expandIcon: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.ArrowIcon,
        alt: "arrow"
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.id
        }, [
          (item.expandable || _ctx.expandable)
            ? (_openBlock(), _createBlock(_component_a_collapse_panel, {
                key: item.id
              }, {
                header: _withCtx(() => [
                  _createVNode(_component_CustomListItem, { details: item }, _createSlots({ _: 2 }, [
                    _renderList(_ctx.$slots, (_, slot) => {
                      return {
                        name: slot,
                        fn: _withCtx((scope) => [
                          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
                        ])
                      }
                    })
                  ]), 1032, ["details"])
                ]),
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "expanded-body", { details: item })
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createBlock(_component_CustomListItem, {
                key: 1,
                details: item,
                bordered: ""
              }, _createSlots({ _: 2 }, [
                _renderList(_ctx.$slots, (_, slot) => {
                  return {
                    name: slot,
                    fn: _withCtx((scope) => [
                      _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
                    ])
                  }
                })
              ]), 1032, ["details"]))
        ], 64))
      }), 128))
    ]),
    _: 3
  }, 8, ["class"]))
}