import AvataradaIcon from "@/assets/avatarada.svg";
import AvataradaWhiteIcon from "@/assets/avatarada-white.svg";
import PoolroomIcon from "@/assets/platform-icons/poolroom.svg";
import AdapoolsIcon from "@/assets/platform-icons/adapools.svg";
import AdastatIcon from "@/assets/platform-icons/adastat.svg";
import CnetioIcon from "@/assets/platform-icons/cnetio.svg";
import DiscordIcon from "@/assets/social/discord.svg";
import InstagramIcon from "@/assets/social/instagram.svg";
import SlackIcon from "@/assets/social/slack.svg";
import TelegramIcon from "@/assets/social/telegram.svg";
import TwitterIcon from "@/assets/social/twitter.svg";
import EmailIcon from "@/assets/social/email.svg";
import MobileIcon from "@/assets/social/mobile.svg";
import DomainIcon from "@/assets/social/domain.svg";
import GoogleIcon from "@/assets/social/google.svg";
import CloseIcon from "@/assets/icons/close-icon.svg";
import { Provider } from "../../../__generated__/globalTypes";

export const platformIcons = {
  avatarada: AvataradaIcon,
  "avatarada-alt": AvataradaWhiteIcon,
  poolroom: PoolroomIcon,
  adapools: AdapoolsIcon,
  adastat: AdastatIcon,
  cnetio: CnetioIcon,
  [Provider.DISCORD]: DiscordIcon,
  [Provider.INSTAGRAM]: InstagramIcon,
  [Provider.SLACK]: SlackIcon,
  [Provider.TELEGRAM]: TelegramIcon,
  [Provider.TWITTER]: TwitterIcon,
  [Provider.GOOGLE]: GoogleIcon,
  MobileChannel: MobileIcon,
  EmailChannel: EmailIcon,
  DomainChannel: DomainIcon,
  CloseIcon,
};

export const getPlatformIcon = (platform: string) => {
  return platformIcons[platform];
};
