import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "expanded-body" }
const _hoisted_2 = {
  key: 0,
  class: "title-value-container"
}
const _hoisted_3 = { class: "dashboard-app-list__action flex items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_TitleValue = _resolveComponent("TitleValue")!
  const _component_RemoveAppPrompt = _resolveComponent("RemoveAppPrompt")!
  const _component_CustomList = _resolveComponent("CustomList")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, {
    title: _ctx.t('Apps'),
    class: "dashboard-app-list"
  }, {
    "title-right": _withCtx(() => [
      _createVNode(_component_a_button, {
        size: "small",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-add-new')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("+ Add new")), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CustomList, {
        list: _ctx.appList,
        expandable: ""
      }, {
        icon: _withCtx(({ details }) => [
          _createVNode(_component_PlatformIcon, {
            class: "dashboard-app-list__item-logo",
            name: details.name,
            logo: details.logo40,
            size: 40,
            "fallback-initial-icon": ""
          }, null, 8, ["name", "logo"])
        ]),
        "expanded-body": _withCtx(({ details }) => [
          _createElementVNode("div", _hoisted_1, [
            (details?.homepageUri || details?.supportEmail?.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (details?.homepageUri)
                    ? (_openBlock(), _createBlock(_component_TitleValue, {
                        key: 0,
                        title: _ctx.t('Main page link'),
                        horizontal: ""
                      }, {
                        value: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            type: "link",
                            class: "title-value-container__main-page-link p-0",
                            onClick: ($event: any) => (_ctx.openInNewTab(details?.homepageUri))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(details?.homepageUri), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["title"]))
                    : _createCommentVNode("", true),
                  (details?.supportEmail?.value)
                    ? (_openBlock(), _createBlock(_component_TitleValue, {
                        key: 1,
                        title: _ctx.t('Developer\'s email'),
                        value: details?.supportEmail?.value,
                        horizontal: ""
                      }, null, 8, ["title", "value"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (details.hasPendingAction)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_a_button, {
                      size: "small",
                      disabled: _ctx.removingAppLoading,
                      onClick: ($event: any) => (_ctx.$emit('on-add-new', details))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Continue Setup")), 1)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"]),
                    _createVNode(_component_RemoveAppPrompt, {
                      "app-id": details.id,
                      class: "ml-2",
                      onOnRemoveAppRequest: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removingAppLoading = true)),
                      onOnRemoveAppSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removingAppLoading = false)),
                      onOnRemoveAppError: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removingAppLoading = false))
                    }, null, 8, ["app-id"])
                  ], 64))
                : (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    size: "small",
                    onClick: ($event: any) => (_ctx.$emit('on-configure', details))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Configure")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
            ])
          ])
        ]),
        _: 1
      }, 8, ["list"])
    ]),
    _: 1
  }, 8, ["title"]))
}