import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "configure-oauth2 mt-6" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "m-0" }
const _hoisted_4 = { class: "m-0 whitespace-pre-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormGroup, {
      class: "configure-oauth2__form",
      model: _ctx.localFormState,
      loading: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_typography_paragraph, { class: "mb-1 font-bold" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Select a Redirect URI")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_InputDropdown, {
          value: _ctx.localFormState.redirectUri,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormState.redirectUri) = $event)),
          name: "redirectUri",
          placeholder: "Select Redirect Uri",
          options: _ctx.redirectUriOptions
        }, null, 8, ["value", "options"]),
        _createVNode(_component_a_typography_title, { level: 4 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Manual Integration")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, { class: "mb-1" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Use the following OAuth2 URL to login with {merchantBrand}", {
            merchantBrand: _ctx.config.merchantBrand,
          })), 1)
          ]),
          _: 1
        }),
        (_ctx.applicationDetails.clientId && _ctx.localFormState.redirectUri)
          ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
              key: 0,
              copyable: "",
              class: "configure-oauth2__copyable-pre mb-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("pre", _hoisted_2, "          " + _toDisplayString(_ctx.generateOAuth2URL(
              _ctx.applicationDetails.clientId,
              _ctx.localFormState.redirectUri
            )) + "\n        ", 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_divider, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Or")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_title, { level: 4 }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Automatic Integration")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "Include the Avatarada Platform Library on your web pages that integrate Avatarada Sign-In",
          {
            merchantBrand: _ctx.config.merchantBrand,
          }
        )), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, {
      copyable: "",
      class: "mt-1 mb-6 configure-oauth2__copyable-pre"
    }, {
      default: _withCtx(() => [
        _createElementVNode("pre", _hoisted_3, "        " + _toDisplayString(`<script src="https://avatarada.io/app/aplatform.js" async defer></script>`) + "\n      ", 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(
          "To create a Sign-In button that uses the default settings, add a div element with the class a-signin, data-client-id and data-redirect-uri to your sign-in page. If no data-on-success will be set, you'll need to get requested data manually and handle the authentication window.",
          {
            merchantBrand: _ctx.config.merchantBrand,
          }
        )), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, {
      copyable: "",
      class: "my-1 configure-oauth2__copyable-pre"
    }, {
      default: _withCtx(() => [
        _createElementVNode("pre", _hoisted_4, _toDisplayString(`<div 
  class="a-signin"
  data-on-success="onSuccess"
  data-on-error="onError"
  data-client-id="${_ctx.applicationDetails.clientId}"
  data-redirect-uri="${_ctx.localFormState.redirectUri}"
/>

<script>
  window["onSuccess"] = (requestedData) => console.log(requestedData);
  window["onError"] = (error) => console.error(error);
</script>`) + "\n", 1)
      ]),
      _: 1
    })
  ]))
}