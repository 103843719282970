
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { useRouter } from "vue-router";
import { ApplicationType } from "@/web/views/Developer/Configure/ConfigureApp.vue";
import Step3LinksBody from "@/web/views/Developer/AddApp/Step3Links/Step3LinksBody.vue";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { redirectUrlFormatter } from "@/developer/utils/appList";
import { message } from "ant-design-vue";
import { useArrayForms } from "@/shared/composables/form/useArrayForms";
import isEmpty from "lodash/isEmpty";
import { RedirectLinksType } from "@/web/views/Developer/AddApp/AddApp.vue";
import { apiErrorCodes } from "@/shared/utils/constants";

export default defineComponent({
  components: {
    FormGroup,
    Step3LinksBody,
  },
  props: {
    initialFormState: {
      type: Object as PropType<ApplicationType>,
      required: true,
    },
  },
  emits: ["on-next-step"],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const { applicationState, updateAppListLoading, handleUpdateApplication } =
      useDeveloperApplications();

    const urlRedirectLists = useArrayForms<RedirectLinksType>({
      urlRedirect: null,
    });

    const localFormState = reactive({
      homepageUri: props.initialFormState?.homepageUri,
      tosUri: props.initialFormState?.tosUri,
      privacyPolicyUri: props.initialFormState?.privacyPolicyUri,
      redirectUris: urlRedirectLists.formCollection,
    });

    const redirect = ref(props?.initialFormState?.redirect);
    if (redirect.value && !isEmpty(redirect.value)) {
      // Assign values to URL Redirect List
      localFormState.redirectUris = redirect.value;
    }

    // Add Item
    const onUrlRedirectUpdate = () => {
      /**
       * We just need to add 1 item that doesn't need to be checked
       * whether we add or subtract from array, so call addFormItem
       */
      urlRedirectLists.addFormItem();
    };

    // Remove Item
    const handleRemoveUrlRedirect = (index: number) => {
      urlRedirectLists.deleteFormItem(index);
    };

    // On Continue Button
    const handleSubmit = async () => {
      try {
        await handleUpdateApplication({
          input: {
            id: applicationState.id,
            homepageUri: localFormState.homepageUri,
            tosUri: localFormState.tosUri,
            privacyPolicyUri: localFormState.privacyPolicyUri,
            redirectUris: redirectUrlFormatter(localFormState.redirectUris),
          },
        });

        message.info(t("App Links Successfully Updated."));
      } catch (error) {
        console.log("Error on Configure Links", error);

        // reset URLs if there's a sub domain allowed error
        if (
          error &&
          String(error).includes(apiErrorCodes.SUBDOMAIN_NOT_ALLOWED.toString())
        ) {
          localFormState.homepageUri = props.initialFormState?.homepageUri;
          localFormState.tosUri = props.initialFormState?.tosUri;
          localFormState.privacyPolicyUri =
            props.initialFormState?.privacyPolicyUri;
        }
      }
    };

    // On Back Button
    const handleBack = () => {
      router.back();
    };

    return {
      t,
      router,
      localFormState,
      loading: computed(() => updateAppListLoading.value),
      onUrlRedirectUpdate,
      handleRemoveUrlRedirect,
      handleSubmit,
      handleBack,
    };
  },
});
