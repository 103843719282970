import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step-2-category" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex justify-between" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_Step2CategoryBody = _resolveComponent("Step2CategoryBody")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormGroup, {
      class: "step-2-category-form",
      model: _ctx.localFormStateModel,
      loading: _ctx.loading,
      onFinish: _ctx.handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CommonCardLayout, null, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_button, {
                  type: "text",
                  class: "p-0",
                  size: "small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-prev-step')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: _ctx.BackIcon,
                      alt: "back",
                      class: "mr-3",
                      height: "24"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_typography_title, {
                  level: 3,
                  class: "m-0"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("New App")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_a_typography_title, {
                level: 4,
                class: "m-0 font-normal"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Step 2")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_a_divider, { class: "card-internal-divider mb-4" }),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_a_button, {
                type: "text",
                class: "py-2 px-4",
                disabled: _ctx.loading,
                onClick: _ctx.handleBack
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "mr-2 mb-0.5",
                    alt: "arrow",
                    src: _ctx.ArrowIcon,
                    style: _normalizeStyle({ transform: 'rotate(180deg)', height: '12px' })
                  }, null, 12, _hoisted_6),
                  _createTextVNode(" " + _toDisplayString(_ctx.t("Previous")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                "html-type": "submit",
                loading: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
                ]),
                _: 1
              }, 8, ["loading"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Step2CategoryBody, {
              value: _ctx.localFormStateModel,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormStateModel) = $event)),
              loading: _ctx.loading
            }, null, 8, ["value", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "loading", "onFinish"])
  ]))
}