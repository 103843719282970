
import CustomList from "@/shared/components/CustomList/CustomList.vue";
import { CustomListItemType } from "@/shared/components/CustomList/CustomListItem.vue";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import TitleValue from "@/shared/components/TitleValue.vue";
import { defineComponent, PropType, ref } from "vue";
import { openInNewTab } from "@/shared/utils/browser";
import { useI18n } from "vue-i18n";
import RemoveAppPrompt from "@/web/views/Developer/AddApp/AddAppComponents/RemoveAppPrompt.vue";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";

export default defineComponent({
  components: {
    CommonCardLayout,
    CustomList,
    TitleValue,
    RemoveAppPrompt,
    PlatformIcon,
  },
  emits: ["on-add-new", "on-configure"],
  props: {
    appList: {
      type: Array as PropType<CustomListItemType[]>,
    },
  },
  setup() {
    const { t } = useI18n();
    /**
     * Note:
     * This is the fastest loading indicator because composable will not work since
     * it was called on the child. In normal circumstances do not use this approach.
     */
    const removingAppLoading = ref(false);

    return {
      t,
      removingAppLoading,
    };
  },
  methods: { openInNewTab },
});
