
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BackIcon from "@/assets/icons/back.svg";
import ArrowIcon from "@/assets/icons/arrow.svg";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import { useRouter } from "vue-router";
import { CurrentStepType } from "@/developer/utils/constants";
import { useVModel } from "vue-composable";
import { CreateApplication_createApplication_ApplicationWithSecret } from "@/api/developer/__generated__/CreateApplication";

export default defineComponent({
  components: {
    InputDropdown,
    InputSwitch,
  },
  props: {
    value: {
      type: Object as PropType<CreateApplication_createApplication_ApplicationWithSecret>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const localFormStateModel = useVModel(props, "value");
    const router = useRouter();

    // Mock Options
    const categoryOptions = [
      { label: "Business", value: "Business" },
      { label: "eCommerce", value: "eCommerce" },
      { label: "Personal blog", value: "Personal blog" },
      { label: "News", value: "News" },
      { label: "Websites", value: "Websites" },
    ];

    return {
      t,
      router,
      BackIcon,
      ArrowIcon,
      CurrentStepType,
      categoryOptions,
      localFormStateModel,
    };
  },
  methods: {
    makeRequiredRule,
  },
});
