
import { computed, defineComponent, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import Step2CategoryBody from "@/web/views/Developer/AddApp/Step2Category/Step2CategoryBody.vue";
import { useRouter } from "vue-router";
import { ApplicationType } from "@/web/views/Developer/Configure/ConfigureApp.vue";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    FormGroup,
    Step2CategoryBody,
  },
  props: {
    initialFormState: {
      type: Object as PropType<ApplicationType>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const localFormState = reactive({
      category: props.initialFormState?.category || null,
      isPublic: props.initialFormState?.isPublic || false,
    });

    const { applicationState, updateAppListLoading, handleUpdateApplication } =
      useDeveloperApplications();

    // On Continue Button
    const handleSubmit = async () => {
      try {
        await handleUpdateApplication({
          input: {
            id: applicationState.id,
            category: localFormState.category,
            isPublic: localFormState.isPublic,
          },
        });

        message.info(t("App Category Successfully Updated."));
      } catch (error) {
        console.log("Error on Configure Category", error);
      }
    };

    // On Back Button
    const handleBack = () => {
      router.back();
    };

    return {
      t,
      router,
      localFormState,
      loading: computed(() => updateAppListLoading.value),
      handleSubmit,
      handleBack,
    };
  },
});
