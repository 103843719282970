/**
 * Get File from URL, the file could be the internet or browser cache/memory
 *
 * @param url - file absolute url
 * @param fileName - file filename, default image.jpg
 * @returns
 */
export const getImageFromUrl = async (url: string, fileName = "image.jpg") => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new File([blob], fileName, {
    type: "image/jpeg",
  });
};
