
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  makeRequiredRule,
  makeUrlRule,
  isValidUrl,
  makeURLDomainRule,
  isDomainUrl,
} from "@/shared/utils/validators/commonValidators";
import InputText from "@/shared/components/Forms/InputText.vue";
import { useVModel } from "vue-composable";
import CheckIcon from "@/assets/check-icon.svg";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";
import { ChannelType } from "../../../../__generated__/globalTypes";

export default defineComponent({
  components: { InputText },
  props: {
    value: {
      type: String,
      default: "",
    },
    verified: {
      type: Boolean,
      default: false,
    },
    showVerificationStatus: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  setup(props) {
    const { t } = useI18n();
    const localValue = useVModel(props, "value");
    const isDomainVerified = ref(false);

    // Filter channel type domain here
    const { channelList, loading } = useCommunicationChannelList({
      isVerified: true,
      channelType: ChannelType.DOMAIN,
    });

    return {
      t,
      localValue,
      handleValidate: (value) => {
        console.log("handleValidate:value", value);
      },
      CheckIcon,
      loading,
      isDomainVerified,
      channelList,
    };
  },
  methods: {
    makeRequiredRule,
    makeUrlRule,
    isValidUrl,
    makeURLDomainRule,
    isDomainUrl,
  },
});
