
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import AvatarUploader from "@/shared/components/AvatarUploader.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import AuthorizedDomainArray from "@/web/views/Developer/AddApp/Step1Details/Step1DetailsAuthorizedDomainArray.vue";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import { useVModel } from "vue-composable";
import isEmpty from "lodash/isEmpty";
import { CreateApplication_createApplication_ApplicationWithSecret } from "@/api/developer/__generated__/CreateApplication";
import routePaths from "@/web/router/routePaths";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import { channelListToSelectOption } from "@/shared/utils/options";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    AvatarUploader,
    InputText,
    AuthorizedDomainArray,
    InputDropdown,
    InputSwitch,
  },
  props: {
    value: {
      type: Object as PropType<CreateApplication_createApplication_ApplicationWithSecret>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    channelListLoading: Boolean,
    channelList: {
      type: Array as PropType<
        | (CommunicationChannels_communicationChannels_CommunicationChannelResults_results | null)[]
      >,
    },
    isEditState: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-domain", "remove-domain", "avatar-change"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const localFormStateModel = useVModel(props, "value");

    const handleAddDomain = () => {
      return router.push(routePaths.communicationChannels);
    };

    const handleAvatarChange = (avatar) => {
      /**
       * FIXME: Add v-model:value on Avatar and File
       * Uploader to sync url values
       */
      if (localFormStateModel.value?.logo400) {
        localFormStateModel.value.logo400 = avatar;
      }
      emit("avatar-change", avatar);
    };

    return {
      t,
      handleAddDomain,
      domainOptions: computed(() =>
        channelListToSelectOption(props.channelList, "DomainChannel")
      ),
      emailOptions: computed(() =>
        channelListToSelectOption(props.channelList, "EmailChannel")
      ),
      localFormStateModel,
      loadingValue: computed(() => props.channelListLoading || props.loading),
      handleAvatarChange,
    };
  },
  methods: {
    makeRequiredRule,
    isEmpty,
  },
});
