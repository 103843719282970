import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step-1-details-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_AvatarUploader = _resolveComponent("AvatarUploader")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_AuthorizedDomainArray = _resolveComponent("AuthorizedDomainArray")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_typography_paragraph, { class: "mt-6 mb-2 m-0 font-normal" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Service Logo")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AvatarUploader, {
      avatar: _ctx.localFormStateModel.logo400,
      class: "mb-6",
      "text-instruction": _ctx.t('Drop your service logo here or browse'),
      onChange: _ctx.handleAvatarChange
    }, null, 8, ["avatar", "text-instruction", "onChange"]),
    _createVNode(_component_InputText, {
      value: _ctx.localFormStateModel.name,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormStateModel.name) = $event)),
      label: _ctx.t('App Name'),
      name: "name",
      rules: [_ctx.makeRequiredRule(_ctx.t('App Name is required'))]
    }, null, 8, ["value", "label", "rules"]),
    _createVNode(_component_a_typography_paragraph, { class: "mt-6 m-0 font-normal" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Authorized domains")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_typography_paragraph, { class: "mt-2 m-0 font-normal lighter small" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("For Avatarada technical updates notifications")), 1)
      ]),
      _: 1
    }),
    (_ctx.channelListLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: "",
          paragraph: false,
          class: "step-1-details-body__domain-loading-skeleton mt-2 mb-6"
        }))
      : (_ctx.isEmpty(_ctx.domainOptions))
        ? (_openBlock(), _createBlock(_component_a_alert, {
            key: 1,
            type: "error",
            class: "step-1-details-body__no-channel-warning animate-shake mt-2 mb-6"
          }, {
            message: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("You don't have any domains at communication channels.")) + " ", 1),
              _createVNode(_component_i18n_t, {
                keypath: "Firstly you need to add them {addDomains}",
                tag: "div",
                class: "ant-typography small m-0",
                scope: "global"
              }, {
                addDomains: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "link",
                    size: "small",
                    disabled: _ctx.loadingValue,
                    onClick: _ctx.handleAddDomain
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("here")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_AuthorizedDomainArray, {
        "form-array": _ctx.localFormStateModel.authorizedDomains,
        "onUpdate:form-array": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormStateModel.authorizedDomains) = $event)),
        "domain-options": _ctx.domainOptions,
        disabled: _ctx.loadingValue,
        onRemoveDomain: _cache[2] || (_cache[2] = (index) => _ctx.$emit('remove-domain', index))
      }, null, 8, ["form-array", "domain-options", "disabled"]),
      _createVNode(_component_a_button, {
        class: "mt-2",
        disabled: _ctx.loadingValue,
        size: "small",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update-domain')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("+ Add one more")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_InputSwitch, {
        value: _ctx.localFormStateModel.includeSubdomain,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localFormStateModel.includeSubdomain) = $event)),
        label: _ctx.t('Include sub domains'),
        name: "includeSubdomain",
        class: "mt-4",
        disabled: _ctx.loadingValue
      }, null, 8, ["value", "label", "disabled"])
    ], 512), [
      [_vShow, !_ctx.isEmpty(_ctx.domainOptions) && !_ctx.channelListLoading]
    ]),
    (_ctx.channelListLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 2,
          active: "",
          paragraph: { rows: 1, width: '100%' },
          class: "step-1-details-body__email-loading-skeleton"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          _withDirectives(_createVNode(_component_InputDropdown, {
            value: _ctx.localFormStateModel.supportEmail,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localFormStateModel.supportEmail) = $event)),
            label: _ctx.t('Support email (public)'),
            name: "supportEmail",
            placeholder: _ctx.t('Select email from communication channels'),
            options: _ctx.emailOptions,
            disabled: _ctx.loadingValue,
            loading: _ctx.channelListLoading,
            rules: [_ctx.makeRequiredRule(_ctx.t('Support Email is required'))]
          }, null, 8, ["value", "label", "placeholder", "options", "disabled", "loading", "rules"]), [
            [_vShow, !_ctx.isEmpty(_ctx.emailOptions)]
          ]),
          (_ctx.isEmpty(_ctx.emailOptions))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_a_typography_paragraph, { class: "mt-6 m-0 font-normal" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Support email (public)")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_alert, {
                  type: "error",
                  class: "step-1-details-body__no-channel-warning animate-shake mt-2 mb-6"
                }, {
                  message: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("You don't have any emails at communication channels.")) + " ", 1),
                    _createVNode(_component_i18n_t, {
                      keypath: "Firstly you need to add them {addDomains}",
                      tag: "div",
                      class: "ant-typography small m-0",
                      scope: "global"
                    }, {
                      addDomains: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "link",
                          size: "small",
                          disabled: _ctx.loadingValue,
                          onClick: _ctx.handleAddDomain
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("here")), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}