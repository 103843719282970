
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ConfirmModal from "@/shared/components/Modals/ConfirmModal.vue";
import { ConfirmModalTypes } from "@/shared/components/Modals/ConfirmModal.vue";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";

export default defineComponent({
  emits: [
    "on-remove-app-request",
    "on-remove-app-error",
    "on-remove-app-success",
  ],
  props: {
    appId: String,
    loading: Boolean,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      applicationLoading,
      deleteApplicationLoading,
      handleDeleteApplication,
    } = useDeveloperApplications(undefined, false);

    // Remove App
    const handleRemoveApp = async () => {
      try {
        emit("on-remove-app-request");
        await handleDeleteApplication({ id: props.appId });
        emit("on-remove-app-success", props.appId);
      } catch (error) {
        emit("on-remove-app-error", error);
        console.log("RemoveAppPrompt:error when trying to remove app", error);
      }
    };

    return {
      t,
      applicationLoading,
      deleteApplicationLoading,
      handleRemoveApp,
      ConfirmModalTypes,
    };
  },
  components: { ConfirmModal },
});
