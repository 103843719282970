import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_RemoveAppPrompt = _resolveComponent("RemoveAppPrompt")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_ConfigureDetails = _resolveComponent("ConfigureDetails")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_ConfigureCategory = _resolveComponent("ConfigureCategory")!
  const _component_ConfigureLinks = _resolveComponent("ConfigureLinks")!
  const _component_ConfigureOAuth2 = _resolveComponent("ConfigureOAuth2")!
  const _component_ConfigureAPI = _resolveComponent("ConfigureAPI")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('For developers'),
    class: "configure-app"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonCardLayout, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                type: "text",
                class: "p-0",
                size: "small",
                onClick: _ctx.handleBack
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.BackIcon,
                    alt: "back",
                    class: "mr-3",
                    height: "24"
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_typography_title, {
                level: 3,
                class: "m-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Settings")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_RemoveAppPrompt, {
              "app-id": _ctx.applicationState.id,
              onOnRemoveAppSuccess: _ctx.handleOnRemoveAppSuccess
            }, null, 8, ["app-id", "onOnRemoveAppSuccess"])
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.applicationLoading)
            ? (_openBlock(), _createBlock(_component_a_skeleton, {
                key: 0,
                active: "",
                paragraph: { rows: 4 },
                loading: _ctx.applicationLoading,
                class: "pa-40"
              }, null, 8, ["loading"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_a_tabs, {
                  activeKey: _ctx.activeKey,
                  "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
                  type: "card"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_component_a_tab_pane, {
                      key: _ctx.ConfigureAppTabType.DETAILS,
                      tab: _ctx.t('Details')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ConfigureDetails, { "initial-form-state": _ctx.editFormState }, null, 8, ["initial-form-state"])
                      ]),
                      _: 1
                    }, 8, ["tab"])),
                    (_openBlock(), _createBlock(_component_a_tab_pane, {
                      key: _ctx.ConfigureAppTabType.CATEGORY,
                      tab: _ctx.t('Category')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ConfigureCategory, { "initial-form-state": _ctx.editFormState }, null, 8, ["initial-form-state"])
                      ]),
                      _: 1
                    }, 8, ["tab"])),
                    (_openBlock(), _createBlock(_component_a_tab_pane, {
                      key: _ctx.ConfigureAppTabType.LINKS,
                      tab: _ctx.t('Links')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ConfigureLinks, { "initial-form-state": _ctx.editFormState }, null, 8, ["initial-form-state"])
                      ]),
                      _: 1
                    }, 8, ["tab"])),
                    (_openBlock(), _createBlock(_component_a_tab_pane, {
                      key: _ctx.ConfigureAppTabType.OAUTH2,
                      tab: 
              _ctx.t('{merchantBrand} Link', { merchantBrand: _ctx.config.merchantBrand })
            
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ConfigureOAuth2, { "application-details": _ctx.application }, null, 8, ["application-details"])
                      ]),
                      _: 1
                    }, 8, ["tab"])),
                    (_openBlock(), _createBlock(_component_a_tab_pane, {
                      key: _ctx.ConfigureAppTabType.API,
                      tab: _ctx.t('API')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ConfigureAPI, { "initial-form-state": _ctx.editFormState }, null, 8, ["initial-form-state"])
                      ]),
                      _: 1
                    }, 8, ["tab"]))
                  ]),
                  _: 1
                }, 8, ["activeKey"])
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}