/**
 * This is used on the new app page
 */
export enum CurrentStepType {
  DETAILS = "details",
  CATEGORY = "category",
  LINKS = "links",
}

/**
 * These are the steps that needs application ID
 * If visited directly, redirect to step 1
 */
export const AddNewAppProtectedSteps: string[] = [
  CurrentStepType.CATEGORY,
  CurrentStepType.LINKS,
];
