
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { computed, defineComponent, PropType, ref, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import BackIcon from "@/assets/icons/back.svg";
import ArrowIcon from "@/assets/icons/arrow.svg";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { useRouter } from "vue-router";
import { CurrentStepType } from "@/developer/utils/constants";
import Step1DetailsBody from "@/web/views/Developer/AddApp/Step1Details/Step1DetailsBody.vue";
import routeNames from "@/web/router/routeNames";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { useVModel } from "vue-composable";
import { getImageFromUrl } from "@/shared/utils/fileUtils";
import { authorizedDomainFormatter } from "@/developer/utils/appList";
import { CreateApplication_createApplication_ApplicationWithSecret } from "@/api/developer/__generated__/CreateApplication";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";

export default defineComponent({
  emits: ["on-next-step", "update-domain", "remove-domain"],
  props: {
    value: {
      type: Object as PropType<CreateApplication_createApplication_ApplicationWithSecret>,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const localFormStateModel = useVModel(props, "value");
    const avatarFile = ref();
    const {
      applicationState,
      createAppListLoading,
      updateAppListLoading,
      handleCreateApplication,
      handleUpdateApplication,
    } = useDeveloperApplications();

    /**
     * Get channel list to be used on email and domain list dropdown
     */
    const { channelList, loading: channelListLoading } =
      useCommunicationChannelList({ isVerified: true });

    onBeforeMount(() => {
      /**
       * Before mounting, check first if application state and localFormStateModel is not empty
       *
       * The user could be going back to previous step and the logo src using a blob file
       * Use logo src coming from backend
       */
      if (
        localFormStateModel.value &&
        applicationState.id &&
        applicationState.logo400
      ) {
        /**
         * Update logo localFormState when the application state logo is not the same
         * Always use applicationState since it's the actual data saved on the backend
         */
        if (applicationState.logo400 !== localFormStateModel.value?.logo400) {
          localFormStateModel.value.logo400 = applicationState.logo400;
        }
      }
    });

    /** Format Image from URL to be passed on endpoint
     *  TODO: Add onFileChanged on File Uploader
     */
    const handleAvatarChange = async (url) => {
      const file = await getImageFromUrl(url);
      avatarFile.value = file;
    };

    /** Return the IDs of the selected domains
     * Endpoint accepts only the IDs of domains
     */
    const parsedAuthorizedDomain = computed(() =>
      authorizedDomainFormatter(localFormStateModel.value?.authorizedDomains)
    );

    // On Continue Button
    const handleSubmit = async () => {
      try {
        /** Check if we call Create Application or Update Application */
        if (applicationState.id) {
          await handleUpdateApplication({
            input: {
              id: applicationState.id,
              name: localFormStateModel.value?.name,
              authorizedDomainChannelIds: parsedAuthorizedDomain.value,
              includeSubdomain: localFormStateModel.value?.includeSubdomain,
              supportEmailChannelId: localFormStateModel.value?.supportEmail,
            },
            logo: avatarFile.value,
          });
        } else {
          await handleCreateApplication({
            input: {
              name: localFormStateModel.value?.name,
              authorizedDomainChannelIds: parsedAuthorizedDomain.value,
              includeSubdomain: localFormStateModel.value?.includeSubdomain,
              supportEmailChannelId: localFormStateModel.value?.supportEmail,
            },
            logo: avatarFile.value,
          });
        }

        // emit to category page
        emit("on-next-step", CurrentStepType.CATEGORY);
      } catch (error) {
        console.log("Error on Step 1 Details", error);
      }
    };

    // On Back Button
    const handleBack = () => {
      // go back to dashboard
      router.push({ name: routeNames.applications });
    };

    return {
      t,
      router,
      BackIcon,
      ArrowIcon,
      localFormStateModel,
      CurrentStepType,
      channelList,
      channelListLoading,
      loading: computed(
        () => createAppListLoading.value || updateAppListLoading.value
      ),
      handleSubmit,
      handleBack,
      handleAvatarChange,
      applicationState,
    };
  },
  components: {
    CommonCardLayout,
    FormGroup,
    Step1DetailsBody,
  },
});
