
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
  components: {},
});
