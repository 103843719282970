import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["title-value", [_ctx.horizontal && 'horizontal']])
  }, [
    _renderSlot(_ctx.$slots, "title", {}, () => [
      _createVNode(_component_a_typography_paragraph, _mergeProps({ class: "title-value__title small lighter m-0" }, _ctx.$attrs.titleProps), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 16)
    ]),
    _renderSlot(_ctx.$slots, "value", {}, () => [
      _createVNode(_component_a_typography_paragraph, _mergeProps({ class: "title-value__value small m-0" }, _ctx.$attrs.valueProps), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ]),
        _: 1
      }, 16)
    ])
  ], 2))
}