import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: "",
        paragraph: { rows: 4 },
        loading: _ctx.loading,
        class: "pa-40"
      }, null, 8, ["loading"]))
    : (_openBlock(), _createBlock(_component_CommonCardLayout, {
        key: 1,
        title: _ctx.t('Apps')
      }, {
        "title-right": _withCtx(() => [
          _createVNode(_component_a_button, {
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-add-new')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("+ Add new")), 1)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_typography_title, {
            level: 4,
            class: "font-normal text-center my-9 lighter"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("You have no connected apps")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]))
}