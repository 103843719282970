
import { computed, defineComponent, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { Application_application_ApplicationWithSecret } from "@/api/developer/__generated__/Application";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";
import { addInternetProtocol } from "@/shared/utils/internet";
import { generateOAuth2URL } from "@/shared/utils/avatarada";
import { config } from "@/shared/utils/config";

export default defineComponent({
  components: { FormGroup, InputDropdown },
  props: {
    applicationDetails: {
      type: Object as PropType<
        Partial<Application_application_ApplicationWithSecret>
      >,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const redirectUriOptions = computed(() =>
      props.applicationDetails.redirectUris?.map((uri) => ({
        label: uri,
        value: addInternetProtocol(uri as string), // uri will not be undefined or null
      }))
    );

    const localFormState = reactive({
      redirectUri: redirectUriOptions.value?.[0].value ?? "",
    });

    return { t, config, localFormState, redirectUriOptions };
  },
  methods: { generateOAuth2URL },
});
