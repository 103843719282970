import gql from "graphql-tag";

// used Developer Applications since query Applications exists
export const applicationsGql = gql`
  query DeveloperApplications {
    applications {
      ... on ApplicationResults {
        results {
          id
          clientId
          user {
            id
            username
            created
            wallet {
              id
              stakingKeyHash
            }
            name

            avatar40
            avatar80
            avatar400
            pendingAction
          }
          redirectUris
          name
          created
          updated
          logo40
          logo80
          logo400
          authorizedDomains {
            id
            value
            isVerified
            isPrimary
            isPublic
            verified
            verificationLastAttempt
            verificationAttemptCount
            created
            updated
          }
          includeSubdomain
          supportEmail {
            id
            created
            value
            isVerified
            isPrimary
            isPublic
            verified
            verificationCodeLastSent
            verificationLastAttempt
            verificationAttemptCount
            updated
          }
          pendingStep
          category
          isPublic
          homepageUri
          tosUri
          privacyPolicyUri
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
