
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BackIcon from "@/assets/icons/back.svg";
import ArrowIcon from "@/assets/icons/arrow.svg";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { useRouter } from "vue-router";
import { CurrentStepType } from "@/developer/utils/constants";
import { message } from "ant-design-vue";
import Step3LinksBody from "@/web/views/Developer/AddApp/Step3Links/Step3LinksBody.vue";
import routeNames from "@/web/router/routeNames";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { useVModel } from "vue-composable";
import { redirectUrlFormatter } from "@/developer/utils/appList";
import { CreateApplication_createApplication_ApplicationWithSecret } from "@/api/developer/__generated__/CreateApplication";
import { apiErrorCodes } from "@/shared/utils/constants";

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<CreateApplication_createApplication_ApplicationWithSecret>,
      required: true,
    },
  },
  emits: [
    "on-next-step",
    "update-redirect-url",
    "remove-redirect-url",
    "on-prev-step",
  ],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const localFormStateModel = useVModel(props, "value");

    const {
      applicationState,
      updateAppListLoading,
      handleUpdateApplication,
      handleRefetchDeveloperAppList,
    } = useDeveloperApplications();

    // On Continue Button
    const handleSubmit = async () => {
      try {
        await handleUpdateApplication({
          input: {
            id: applicationState.id,
            homepageUri: localFormStateModel.value?.homepageUri,
            tosUri: localFormStateModel.value?.tosUri,
            privacyPolicyUri: localFormStateModel.value?.privacyPolicyUri,
            redirectUris: redirectUrlFormatter(
              localFormStateModel.value?.redirectUris
            ),
          },
        });

        message.info(t("App Successfully Added"));

        //Refetch Developer App list to update Dashboard
        await handleRefetchDeveloperAppList();
        // go to dashboard
        router.push({ name: routeNames.applications });
      } catch (error) {
        console.log("Error on Step 3 Links", error);

        // reset URLs if there's a sub domain allowed error
        if (
          error &&
          String(error).includes(apiErrorCodes.SUBDOMAIN_NOT_ALLOWED.toString())
        ) {
          localFormStateModel.value.homepageUri = "";
          localFormStateModel.value.tosUri = "";
          localFormStateModel.value.privacyPolicyUri = "";
        }
      }
    };

    // On Back Button
    const handleBack = () => {
      router.back();
    };

    return {
      t,
      router,
      BackIcon,
      ArrowIcon,
      localFormStateModel,
      CurrentStepType,
      loading: computed(() => updateAppListLoading.value),
      handleSubmit,
      handleBack,
    };
  },
  methods: {
    makeRequiredRule,
  },
  components: {
    CommonCardLayout,
    FormGroup,
    Step3LinksBody,
  },
});
