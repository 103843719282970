import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import { Ref } from "@vue/reactivity";

/**
 * Use this function to easily handle forms that requires to have
 * many copy of the same fields on one page.
 *
 * For example:
 * - Submit an ingredient list with different names, gross, and net
 */
export function useArrayForms<T>(
  defaultFormState: T,
  numberOfItems?: number
): {
  formCollection: Ref<(T & { uuid: string })[]>;
  // eslint-disable-next-line no-unused-vars
  deleteFormItem: (index: number) => void;
  addFormItem: () => void;
  // eslint-disable-next-line no-unused-vars
  batchUpdate: (numberOfItems: number) => void;
} {
  const formCollection = ref<(T & { uuid: string })[]>([
    {
      ...cloneDeep(defaultFormState),
      /** Insert uuid automatically so transition group can use it when
      animating list. See https://v3.vuejs.org/api/built-in-components.html#transition-group
      UUID is used as a key and is automatically managed by the trasition-group element of Vue
      See https://vuejs.org/v2/guide/transitions.html#List-Transitions */
      uuid: uuidv4(),
    },
  ]) as Ref<(T & { uuid: string })[]>;

  if (numberOfItems) {
    batchUpdate(numberOfItems);
  }

  function addFormItem() {
    formCollection.value.push({
      ...cloneDeep(defaultFormState),
      uuid: uuidv4(),
    });
  }

  function deleteFormItem(index: number) {
    formCollection.value.splice(index, 1);

    if (index === 0 && formCollection.value.length < 1) {
      formCollection.value.push({
        ...cloneDeep(defaultFormState),
        uuid: uuidv4(),
      });
    }
  }

  function batchUpdate(numberOfItems: number) {
    // If the new number of items is equal to current, do nothing
    if (formCollection.value.length === numberOfItems) {
      return;
    }

    // If the new number of items is greater than current, we subtract
    if (formCollection.value.length > numberOfItems) {
      const itemsToBeDeleted = formCollection.value.length - numberOfItems;
      formCollection.value.splice(numberOfItems, itemsToBeDeleted);
      return;
    }

    // If the new number of items is lesser than current, we add
    const itemsToBeAdded = numberOfItems - formCollection.value.length;
    for (let iteration = 0; iteration < itemsToBeAdded; iteration++) {
      addFormItem();
    }
  }

  return {
    formCollection,
    deleteFormItem,
    addFormItem,
    batchUpdate,
  };
}
