
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { getPlatformIcon } from "@/shared/utils/platformIcons";
import { getFirsts } from "@/shared/utils/stringHelper";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: false,
    },
    loading: {
      default: false,
    },
    fallbackInitialIcon: Boolean,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { getPlatformIcon, getFirsts },
});
