import { ApplicationPendingStep } from "../../../__generated__/globalTypes";
import { i18nTranslate } from "@/plugins/i18n";
import { CurrentStepType } from "@/developer/utils/constants";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";

// Importing from CustomListItem is making an error
export type CustomListItemStatusType = {
  type: string;
  value: string;
};

// see ApplicationPendingStep for other pending steps
export const PendingStepNumberMap = {
  [ApplicationPendingStep.STEP_1]: 1,
  [ApplicationPendingStep.STEP_2]: 1,
  [ApplicationPendingStep.STEP_3]: 2,
};

// pending step route link equivalent for the "Continue setup" feature
export const PendingStepRoutingMap = {
  [ApplicationPendingStep.STEP_1]: CurrentStepType.DETAILS,
  [ApplicationPendingStep.STEP_2]: CurrentStepType.CATEGORY,
  [ApplicationPendingStep.STEP_3]: CurrentStepType.LINKS,
};

/**
 * Get Expected Returned Value
 *
 * @param app list - app list array
 * @returns
 */
export const appListFormatter = (appList) => {
  return appList.map((item) => {
    // Fetch Statuses
    const statuses: CustomListItemStatusType[] = [];
    if (item?.isPublic) {
      statuses.push({ type: "success", value: i18nTranslate("Public") });
    }

    // Fetch Description and Type
    let hasPendingAction = false;
    let description;
    let descriptionType = "danger"; // default is danger
    if (!item?.pendingStep || item.pendingStep === ApplicationPendingStep.A_) {
      // description must be empty
      description = "";
      descriptionType = "";
    } else {
      hasPendingAction = true;
      const pendingStep = PendingStepNumberMap[item?.pendingStep];

      description = i18nTranslate("{pendingStep} out of 3 steps completed", {
        pendingStep,
      });

      if (item?.pendingStep === ApplicationPendingStep.STEP_1) {
        description = i18nTranslate("A step requires action");
      }
    }

    return {
      ...item,
      statuses,
      description,
      descriptionType,
      value: item?.name,
      hasPendingAction,
      continueStep: PendingStepRoutingMap[item?.pendingStep],
    };
  });
};

// Authorized Domain Formatter to be passed on endpoint
export const authorizedDomainFormatter = (authorizedDomain) =>
  map(authorizedDomain, (domain) => domain?.domainName);

// Redirect URL Formatter to be passed on endpoint
export const redirectUrlFormatter = (urlRedirectLists) =>
  map(urlRedirectLists, (url) => url?.urlRedirect);

/**
 * Array Forms Format for Authorized Domains
 */

export const authorizedDomainArrayFormatter = (authorizedDomain) =>
  map(authorizedDomain, (domain) => {
    return { domainName: domain.id, uuid: uuidv4() };
  });

/**
 * Array Forms Format for Redirect URL Formatter
 */
export const redirectUrisArrayFormatter = (redirectUris) =>
  map(redirectUris, (url) => {
    return {
      urlRedirect: url,
      uuid: uuidv4(),
    };
  });
