import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "configure-details" }
const _hoisted_2 = { class: "mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Step1DetailsBody = _resolveComponent("Step1DetailsBody")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormGroup, {
      class: "configure-details-form",
      model: _ctx.localFormState,
      loading: _ctx.loading,
      onFinish: _ctx.handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Step1DetailsBody, {
          value: _ctx.localFormState,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormState) = $event)),
          "channel-list": _ctx.channelList,
          "channel-list-loading": _ctx.channelListLoading,
          loading: _ctx.loading,
          "is-edit-state": "",
          onUpdateDomain: _ctx.onDomainItemsUpdate,
          onRemoveDomain: _ctx.handleRemoveDomain,
          onAvatarChange: _ctx.handleAvatarChange
        }, null, 8, ["value", "channel-list", "channel-list-loading", "loading", "onUpdateDomain", "onRemoveDomain", "onAvatarChange"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "primary",
            class: "save-button mr-2",
            "html-type": "submit",
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Save")), 1)
            ]),
            _: 1
          }, 8, ["loading"]),
          _createVNode(_component_a_button, {
            class: "border-0 py-2 px-4",
            disabled: _ctx.loading,
            onClick: _ctx.handleBack
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Cancel")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["model", "loading", "onFinish"])
  ]))
}