import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import { ApplicationUpdateInput } from "../../../../__generated__/globalTypes";
import {
  addInternetProtocol,
  removeInternetProtocol,
} from "@/shared/utils/internet";
import { UpdateApplication_updateApplication_ApplicationWithSecret } from "@/api/developer/__generated__/UpdateApplication";

/**
 * Format input for GraphQL mutation before sending to the server
 */
export const updateApplicationInputFormatter = (
  input: ApplicationUpdateInput
) => {
  // URL formatters
  if (input.homepageUri) {
    input.homepageUri = addInternetProtocol(input.homepageUri);
  }
  if (input.privacyPolicyUri) {
    input.privacyPolicyUri = addInternetProtocol(input.privacyPolicyUri);
  }
  if (input.tosUri) {
    input.tosUri = addInternetProtocol(input.tosUri);
  }
  if (!isEmpty(input.redirectUris)) {
    input.redirectUris = input.redirectUris?.map((uri) =>
      addInternetProtocol(<string>uri)
    );
  }
  // END URL formatters
  return input;
};

/**
 * Parse graphql query response
 * This is used so that url prefixes are removed because
 * link field have prefixed "https://"
 */
export const applicationResponseFormatter = (
  responseData?: UpdateApplication_updateApplication_ApplicationWithSecret | null
) => {
  if (!responseData) {
    console.log("returned", responseData);
    return responseData;
  }

  // won't be able to mutate original data, clone data
  const data = { ...responseData };

  // URL formatters
  if (data.homepageUri) {
    data.homepageUri = removeInternetProtocol(data.homepageUri);
  }
  if (data.privacyPolicyUri) {
    data.privacyPolicyUri = removeInternetProtocol(data.privacyPolicyUri);
  }
  if (data.tosUri) {
    data.tosUri = removeInternetProtocol(data.tosUri);
  }
  if (data.redirectUris) {
    if (isArray(data.redirectUris)) {
      data.redirectUris = data.redirectUris?.map((uri) =>
        removeInternetProtocol(<string>uri)
      );
    } else {
      /**
       * Convert to string since it's not an array or null
       */
      data.redirectUris = [removeInternetProtocol(String(data.redirectUris))];
    }
  }
  // END URL formatters
  return data;
};
