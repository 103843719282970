
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";
import isEmpty from "lodash/isEmpty";

export type CustomListItemStatusType = {
  type: string;
  value: string;
};

export type CustomListItemType = {
  name: string; // platform name
  value: string; // platform value, e.g. email, mobile number, etc
  statuses: CustomListItemStatusType[];
};

export default defineComponent({
  props: {
    details: {
      type: Object as PropType<CustomListItemType>,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { isEmpty },
  components: { PlatformIcon },
});
