
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import Step1Details from "@/web/views/Developer/AddApp/Step1Details/Step1Details.vue";
import Step2Category from "@/web/views/Developer/AddApp/Step2Category/Step2Category.vue";
import Step3Links from "@/web/views/Developer/AddApp/Step3Links/Step3Links.vue";
import { useArrayForms } from "@/shared/composables/form/useArrayForms";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import isEmpty from "lodash/isEmpty";
import {
  CurrentStepType,
  AddNewAppProtectedSteps,
} from "@/developer/utils/constants";

export type DetailsType = {
  domainName: string | null;
};

export type RedirectLinksType = {
  urlRedirect: string | null;
};

export default defineComponent({
  components: {
    PageLayout,
    Step1Details,
    Step2Category,
    Step3Links,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const currentStep = computed(() => route.params.step as string);

    const { applicationState } = useDeveloperApplications();

    const authorizedDomain = useArrayForms<DetailsType>({
      domainName: null,
    });

    const urlRedirectLists = useArrayForms<RedirectLinksType>({
      urlRedirect: null,
    });

    /**
     * Use application state as initial local form state
     *
     * array values that uses useArrayForms will have
     * to be re-map and done on onBeforeMount hooks
     */
    const localFormState = reactive({
      logo400: applicationState.logo400,
      name: applicationState.name,
      authorizedDomains: authorizedDomain.formCollection,
      includeSubdomain: applicationState.includeSubdomain,
      supportEmail: applicationState.supportEmail?.id,
      category: applicationState.category,
      isPublic: !!applicationState.isPublic,
      homepageUri: applicationState.homepageUri,
      tosUri: applicationState.tosUri,
      privacyPolicyUri: applicationState.privacyPolicyUri,
      redirectUris: urlRedirectLists.formCollection,
    });

    onBeforeMount(() => {
      /**
       * Before mounting, check first if application state is not empty
       * Update only authorizedDomains and redirectUris because other details are
       * set as initial localFormState values
       */
      if (applicationState.id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const applicationFormState: any = {};

        /**
         * Check authorizedDomains first if its not empty before mapping
         */
        if (!isEmpty(applicationState.authorizedDomains)) {
          applicationFormState.authorizedDomains =
            applicationState.authorizedDomains?.map((domain) => ({
              domainName: domain.id,
              uuid: domain.id,
            }));
        }
        /**
         * Check redirectUris first if its not empty before mapping
         */
        if (!isEmpty(applicationState.redirectUris)) {
          applicationFormState.redirectUris =
            applicationState.redirectUris?.map((urlRedirect) => ({
              urlRedirect,
              uuid: urlRedirect,
            }));
        }
        Object.assign(localFormState, applicationFormState);
      } else if (AddNewAppProtectedSteps.includes(currentStep.value)) {
        /**
         * Check if protected steps is accessed without application ID
         */
        console.warn("Something is not right, redirecting to step 1", {
          currentStep: currentStep.value,
        });
        router.push({
          name: routeNames.applicationsAdd,
          params: { step: CurrentStepType.DETAILS },
        });
      }
    });

    // Add Domain Item
    const onDomainItemsUpdate = () => {
      /**
       * We just need to add 1 item that doesn't need to be checked
       * whether we add or subtract from array, so call addFormItem
       */
      authorizedDomain.addFormItem();
    };

    // Remove Domain Item
    const handleRemoveDomain = (index: number) => {
      authorizedDomain.deleteFormItem(index);
    };

    // Add Redirect URL Item
    const onUrlRedirectUpdate = () => {
      /**
       * We just need to add 1 item that doesn't need to be checked
       * whether we add or subtract from array, so call addFormItem
       */
      urlRedirectLists.addFormItem();
    };

    // Remove Redirect URL Item
    const handleRemoveUrlRedirect = (index: number) => {
      urlRedirectLists.deleteFormItem(index);
    };

    const handleNextStep = (step) => {
      router.push({
        name: routeNames.applicationsAdd,
        params: { step },
      });
    };

    const handlePrevStep = () => {
      // go back to dashboard
      router.push({ name: routeNames.applications });
    };

    return {
      t,
      applicationState,
      currentStep,
      handleNextStep,
      CurrentStepType,
      localFormState,
      onDomainItemsUpdate,
      handleRemoveDomain,
      onUrlRedirectUpdate,
      handleRemoveUrlRedirect,
      handlePrevStep,
    };
  },
});
