import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Step1Details = _resolveComponent("Step1Details")!
  const _component_Step2Category = _resolveComponent("Step2Category")!
  const _component_Step3Links = _resolveComponent("Step3Links")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('For developers')
  }, {
    default: _withCtx(() => [
      (_ctx.currentStep === _ctx.CurrentStepType.DETAILS)
        ? (_openBlock(), _createBlock(_component_Step1Details, {
            key: 0,
            value: _ctx.localFormState,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormState) = $event)),
            onOnNextStep: _ctx.handleNextStep,
            onUpdateDomain: _ctx.onDomainItemsUpdate,
            onRemoveDomain: _ctx.handleRemoveDomain
          }, null, 8, ["value", "onOnNextStep", "onUpdateDomain", "onRemoveDomain"]))
        : (_ctx.currentStep === _ctx.CurrentStepType.CATEGORY)
          ? (_openBlock(), _createBlock(_component_Step2Category, {
              key: 1,
              value: _ctx.localFormState,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormState) = $event)),
              onOnNextStep: _ctx.handleNextStep,
              onOnPrevStep: _ctx.handlePrevStep
            }, null, 8, ["value", "onOnNextStep", "onOnPrevStep"]))
          : (_ctx.currentStep === _ctx.CurrentStepType.LINKS)
            ? (_openBlock(), _createBlock(_component_Step3Links, {
                key: 2,
                value: _ctx.localFormState,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localFormState) = $event)),
                onOnNextStep: _ctx.handleNextStep,
                onOnPrevStep: _ctx.handlePrevStep,
                onUpdateRedirectUrl: _ctx.onUrlRedirectUpdate,
                onRemoveRedirectUrl: _ctx.handleRemoveUrlRedirect
              }, null, 8, ["value", "onOnNextStep", "onOnPrevStep", "onUpdateRedirectUrl", "onRemoveRedirectUrl"]))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}