import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "step-2-category-body" }
const _hoisted_2 = { class: "mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputDropdown, {
      value: _ctx.localFormStateModel.category,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormStateModel.category) = $event)),
      label: _ctx.t('Category'),
      name: "category",
      placeholder: "Select Category",
      options: _ctx.categoryOptions,
      disabled: _ctx.loading,
      rules: [_ctx.makeRequiredRule(_ctx.t('Category is required'))]
    }, null, 8, ["value", "label", "options", "disabled", "rules"]),
    _createVNode(_component_InputSwitch, {
      value: _ctx.localFormStateModel.isPublic,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormStateModel.isPublic) = $event)),
      label: _ctx.t('Public'),
      name: "isPublic",
      class: "mt-6 mb-0",
      disabled: _ctx.loading
    }, null, 8, ["value", "label", "disabled"]),
    _createVNode(_component_a_typography_text, null, {
      default: _withCtx(() => [
        _createElementVNode("blockquote", _hoisted_2, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.t("Important!")), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.t(
            "In order to start using this application, you need to set this to public."
          )), 1)
        ])
      ]),
      _: 1
    })
  ]))
}