
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import BackIcon from "@/assets/icons/back.svg";
import ArrowIcon from "@/assets/icons/arrow.svg";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import Step2CategoryBody from "@/web/views/Developer/AddApp/Step2Category/Step2CategoryBody.vue";
import { useRouter } from "vue-router";
import { CurrentStepType } from "@/developer/utils/constants";
import { useVModel } from "vue-composable";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { CreateApplication_createApplication_ApplicationWithSecret } from "@/api/developer/__generated__/CreateApplication";

export default defineComponent({
  emits: ["on-next-step", "on-prev-step"],
  props: {
    value: {
      type: Object as PropType<CreateApplication_createApplication_ApplicationWithSecret>,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const localFormStateModel = useVModel(props, "value");

    const { applicationState, updateAppListLoading, handleUpdateApplication } =
      useDeveloperApplications();

    // On Continue Button
    const handleSubmit = async () => {
      try {
        await handleUpdateApplication({
          input: {
            id: applicationState.id,
            category: localFormStateModel.value?.category,
            isPublic: localFormStateModel.value?.isPublic,
          },
        });

        // emit to Link page
        emit("on-next-step", CurrentStepType.LINKS);
      } catch (error) {
        console.log("Error on Step 2 Category", error);
      }
    };

    // On Back Button
    const handleBack = () => {
      router.back();
    };

    return {
      t,
      router,
      BackIcon,
      ArrowIcon,
      localFormStateModel,
      CurrentStepType,
      loading: computed(() => updateAppListLoading.value),
      handleSubmit,
      handleBack,
    };
  },
  components: {
    CommonCardLayout,
    FormGroup,
    Step2CategoryBody,
  },
});
