
import { i18nTranslate } from "@/plugins/i18n";
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import InputOtp from "@/shared/components/Forms/InputOtp.vue";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";
import attempt from "lodash/attempt";

export enum ConfirmModalTypes {
  // eslint-disable-next-line no-unused-vars
  confirm = "confirm",
  // eslint-disable-next-line no-unused-vars
  danger = "danger",
}

export default defineComponent({
  components: { FormGroup, InputOtp, PlatformIcon },
  props: {
    title: {
      type: String,
      default: i18nTranslate("Confirm"),
    },
    body: {
      type: String,
      default: i18nTranslate("Are you sure?"),
    },
    modalClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: ConfirmModalTypes.confirm,
    },
    confirmText: {
      type: String,
      default: i18nTranslate("Yes, confirm"),
    },
    cancelText: {
      type: String,
      default: i18nTranslate("Cancel"),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // there are some instance where OTP won't be required anymore
    hideOTPInput: Boolean,
  },
  emits: ["close", "confirm"],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const formRef = ref();
    const visible = ref<boolean>(false);
    const inputOtpRef = ref();
    const router = useRouter();

    const formLoginState = reactive({
      otpCode: "",
    });

    const showModal = () => {
      visible.value = true;
    };

    /**
     * This is a method to close the modal programmatically
     * Add to setup return to be able to call via ref to this component.
     */
    const handleCloseModal = () => {
      emit("close");
      visible.value = false;
    };

    const handleConfirmChange = () => {
      emit("confirm", formLoginState.otpCode);
    };

    /**
     * This event os fired when the modal closes by user interaction
     * This is not the same as handleCloseModal
     */
    const handleCancel = () => {
      /**
       * Because destroyOnClose is set for the modal, manually clear the form state
       */
      formLoginState.otpCode = "";
    };

    const clearInput = () => attempt(inputOtpRef.value?.clearInput);

    return {
      t,
      inputOtpRef,
      ConfirmModalTypes,
      visible,
      showModal,
      formRef,
      formLoginState,
      handleCancel,
      handleCloseModal,
      handleBackupCode: () => {
        router.push({ name: routeNames.backupCode });
      },
      handleConfirmChange,
      clearInput,
    };
  },
});
