
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  emits: ["on-add-new"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  components: { CommonCardLayout },
});
