import { computed, reactive } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { applicationsGql } from "@/api/developer/applications";
import { DeveloperApplications_applications_ApplicationResults } from "@/api/developer/__generated__/DeveloperApplications";
import { createApplicationGql } from "@/api/developer/createApplication";
import {
  CreateApplication_createApplication_ApplicationWithSecret,
  CreateApplicationVariables,
  CreateApplication,
} from "@/api/developer/__generated__/CreateApplication";
import { updateApplicationGql } from "@/api/developer/updateApplication";
import {
  UpdateApplication_updateApplication_ApplicationWithSecret,
  UpdateApplicationVariables,
  UpdateApplication,
} from "@/api/developer/__generated__/UpdateApplication";
import { deleteApplicationGql } from "@/api/developer/deleteApplication";
import {
  DeleteApplication_deleteApplication_GenericSuccess,
  DeleteApplicationVariables,
  DeleteApplication,
} from "@/api/developer/__generated__/DeleteApplication";
import { updateClientSecretGql } from "@/api/developer/updateClientSecret";
import {
  UpdateClientSecret_updateClientSecret_ApplicationWithSecret,
  UpdateClientSecretVariables,
  UpdateClientSecret,
} from "@/api/developer/__generated__/UpdateClientSecret";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import isEmpty from "lodash/isEmpty";
import { apiErrorCodes } from "@/shared/utils/constants";
import { applicationGql } from "@/api/developer/application";
import { Application_application_ApplicationWithSecret } from "@/api/developer/__generated__/Application";
import merge from "lodash/merge";
import {
  applicationResponseFormatter,
  updateApplicationInputFormatter,
} from "@/shared/utils/formatters/applicationWithSecretFormatter";

const emptyAppState = {
  __typename: "ApplicationWithSecret",
  id: "",
  clientId: "",
  user: null,
  redirectUris: [],
  name: "",
  created: "",
  updated: "",
  logo40: null,
  logo80: null,
  logo400: null,
  authorizedDomains: [],
  includeSubdomain: false,
  supportEmail: null,
  pendingStep: null,
  category: "",
  isPublic: null,
  homepageUri: "",
  tosUri: "",
  privacyPolicyUri: "",
  clientSecret: "",
};

/**
 * Developer App list composable
 *
 * @param initialFormState - initial application state
 * @param fetchApplicationOnMount - enable/disable application details automatic fetch on mount
 */
export const useDeveloperApplications = (
  initialFormState?,
  fetchApplicationOnMount = true
) => {
  /**
   * Merge initial form state with empty app state
   * Initial form state has values if application is being edited
   */
  const applicationState = reactive<
    Partial<CreateApplication_createApplication_ApplicationWithSecret>
  >(merge(emptyAppState, initialFormState));
  console.log("applicationState", applicationState);

  /**
   * GET DEVELOPER APP LIST
   */
  const developerAppListQuery = useQuery(
    applicationsGql,
    {},
    {
      fetchPolicy: "network-only",
    }
  );

  const appList = computed(() => {
    const parsedResponse =
      parseGqlResponse<DeveloperApplications_applications_ApplicationResults>(
        "ApplicationResults",
        developerAppListQuery.result.value
      );

    return parsedResponse.data?.results || [];
  });
  /**
   * END GET APPLICATION LIST
   */

  // REFETCH ALL APPLICATION LIST
  const handleRefetchDeveloperAppList = async () => {
    developerAppListQuery.refetch();
  };
  // END REFETCH ALL APPLICATION LIST

  /**
   * GET DEVELOPER APPLICATION
   */
  const applicationQuery = useQuery(
    applicationGql,
    () => ({
      id: applicationState.id,
    }),
    () => ({
      enabled: !!applicationState.id && fetchApplicationOnMount, // do not query application details without the required ID
      fetchPolicy: "cache-and-network", // use cache-and-network to get the current and latest data
    })
  );

  const application = computed(() => {
    const parsedResponse =
      parseGqlResponse<Application_application_ApplicationWithSecret>(
        "ApplicationWithSecret",
        applicationQuery.result.value
      );

    return applicationResponseFormatter(parsedResponse?.data);
  });
  /**
   * END DEVELOPER APPLICATION
   */

  // CREATE APPLICATION
  const createApplicationMutation = useCustomMutation<
    CreateApplication,
    CreateApplicationVariables
  >(createApplicationGql, {
    refetchQueries: [{ query: applicationsGql }],
  });

  const handleCreateApplication = async (variables) => {
    const createApplicationResponse = await createApplicationMutation.mutate(
      variables
    );

    const parsedResponse =
      parseGqlResponse<CreateApplication_createApplication_ApplicationWithSecret>(
        "ApplicationWithSecret",
        createApplicationResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("createApplicationResponse:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !createApplicationResponse) {
      throw new Error("Failed to create application");
    }

    // assign the new state if it was created successfully
    if (parsedResponse.data) {
      Object.assign(applicationState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  // END CREATE APPLICATION

  // UPDATE APPLICATION
  const updateApplicationMutation = useCustomMutation<
    UpdateApplication,
    UpdateApplicationVariables
  >(updateApplicationGql);

  const handleUpdateApplication = async (variables) => {
    const updateApplicationResponse = await updateApplicationMutation.mutate({
      input: updateApplicationInputFormatter(variables.input),
      logo: variables.logo,
    });

    const parsedResponse =
      parseGqlResponse<UpdateApplication_updateApplication_ApplicationWithSecret>(
        "ApplicationWithSecret",
        updateApplicationResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("updateApplicationResponse:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !updateApplicationResponse) {
      throw new Error(
        parsedResponse.error?.errors?.map((error) => error.code).join(",")
      );
    }

    return parsedResponse.data;
  };
  // END UPDATE APPLICATION

  /**
   * DELETE APPLICATION
   */
  const deleteApplicationMutation = useCustomMutation<
    DeleteApplication,
    DeleteApplicationVariables
  >(deleteApplicationGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: applicationsGql }],
  });
  const handleDeleteApplication = async ({ id }) => {
    const deleteApplicationResponse = await deleteApplicationMutation.mutate({
      input: { id },
    });
    const parsedResponse =
      parseGqlResponse<DeleteApplication_deleteApplication_GenericSuccess>(
        "GenericSuccess",
        deleteApplicationResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleDeleteEmailChannel:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !deleteApplicationResponse) {
      throw new Error("Failed to delete application");
    }

    return parsedResponse.data;
  };
  /**
   * END DELETE APPLICATION
   */

  // UPDATE CLIENT SECRET KEY
  const updateClientSecretMutation = useCustomMutation<
    UpdateClientSecret,
    UpdateClientSecretVariables
  >(updateClientSecretGql);

  const handleUpdateClientSecret = async ({ id, otpCode }) => {
    const updateClientSecretResponse = await updateClientSecretMutation.mutate({
      input: { id },
      otpCode,
    });

    const parsedResponse =
      parseGqlResponse<UpdateClientSecret_updateClientSecret_ApplicationWithSecret>(
        "ApplicationWithSecret",
        updateClientSecretResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("updateClientSecretResponse:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !updateClientSecretResponse) {
      throw new Error("Failed to update client secret key");
    }

    return parsedResponse.data;
  };
  // END UPDATE CLIENT SECRET KEY

  return {
    appList,
    loading: computed(() => developerAppListQuery.loading.value),
    handleRefetchDeveloperAppList,
    handleCreateApplication,
    createAppListLoading: computed(
      () => createApplicationMutation.loading.value
    ),
    handleUpdateApplication,
    updateAppListLoading: computed(
      () => updateApplicationMutation.loading.value
    ),
    application,
    applicationLoading: computed(() => applicationQuery.loading.value),
    handleDeleteApplication,
    deleteApplicationLoading: computed(
      () => deleteApplicationMutation.loading.value
    ),
    handleUpdateClientSecret,
    updateClientSecretLoading: computed(
      () => updateClientSecretMutation.loading.value
    ),
    applicationState,
  };
};
