import queryString from "query-string";
import { config } from "@/shared/utils/config";
import { removeTrailingSlash } from "@/shared/utils/stringHelper";

const basePublicUrlPathNoTrailingSlash = removeTrailingSlash(
  config.basePublicUrlPath
);

/**
 * Generate OAth2 login link for Avatarada
 *
 * @param client_id
 * @param redirect_uri
 * @returns
 *
 * @example:
 * generateOAuth2URL()
 * =>
 */
export const generateOAuth2URL = (client_id: string, redirect_uri: string) => {
  return queryString.stringifyUrl(
    {
      url: `${basePublicUrlPathNoTrailingSlash}/oauth2`,
      query: { client_id, redirect_uri },
    },
    {
      encode: true, // encode spaces
    }
  );
};

/**
 * Generate public link for Avatarada Profile
 *
 * @param stakingKeyHash
 * @returns
 *
 * @example
 * generatePublicUrl("abc123")
 * => https://avatarada-test.dms.fyi/profile/abc123
 */
export const generatePublicUrl = (stakingKeyHash: string) => {
  return `${basePublicUrlPathNoTrailingSlash}/profile/${stakingKeyHash}`;
};
