
import {
  defineComponent,
  reactive,
  ref,
  watch,
  computed,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import BackIcon from "@/assets/icons/back.svg";
import ConfigureDetails from "@/web/views/Developer/Configure/ConfigureDetails.vue";
import ConfigureCategory from "@/web/views/Developer/Configure/ConfigureCategory.vue";
import ConfigureLinks from "@/web/views/Developer/Configure/ConfigureLinks.vue";
import ConfigureAPI from "@/web/views/Developer/Configure/ConfigureAPI.vue";
import ConfigureOAuth2 from "@/web/views/Developer/Configure/ConfigureOAuth2.vue";
import routeNames from "@/web/router/routeNames";
import { useDeveloperApplications } from "@/shared/composables/Developer/useDeveloperApplications";
import { Application_application_ApplicationWithSecret } from "@/api/developer/__generated__/Application";
import { message } from "ant-design-vue";
import {
  authorizedDomainArrayFormatter,
  redirectUrisArrayFormatter,
} from "@/developer/utils/appList";
import RemoveAppPrompt from "@/web/views/Developer/AddApp/AddAppComponents/RemoveAppPrompt.vue";
import { config } from "@/shared/utils/config";

export enum ConfigureAppTabType {
  DETAILS = "details",
  CATEGORY = "category",
  LINKS = "links",
  API = "api",
  OAUTH2 = "oauth2",
}

export type ApplicationType =
  Partial<Application_application_ApplicationWithSecret> & {
    domain?: {
      domainName: string | null;
      uuid: string;
    }[];
    email?: string | null;
    redirect?: {
      urlRedirect: string | null;
      uuid: string;
    }[];
  };

export default defineComponent({
  components: {
    PageLayout,
    CommonCardLayout,
    ConfigureDetails,
    ConfigureCategory,
    ConfigureLinks,
    ConfigureAPI,
    ConfigureOAuth2,
    RemoveAppPrompt,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const activeKey = ref(ConfigureAppTabType.DETAILS);
    const router = useRouter();
    const ConfigureAppModalRef = ref();
    const editFormState = reactive<ApplicationType>({});

    const app_id = computed(() => route.params.app_id as string);

    const {
      applicationState,
      applicationLoading,
      application,
      handleRefetchDeveloperAppList,
    } = useDeveloperApplications();

    onMounted(() => {
      console.log("onMounted", app_id.value);
      console.log(applicationState);
      if (app_id.value) {
        applicationState.id = app_id.value;
      }
    });

    /**
     * Watch the application value first
     * before assigning to editFormState
     * to make sure the data has been returned
     */
    watch(application, async (value) => {
      if (value) {
        /**
         * Format into Array Forms
         */
        const authorizedDomains = authorizedDomainArrayFormatter(
          value.authorizedDomains
        );
        const redirectUris = redirectUrisArrayFormatter(value.redirectUris);

        /**
         * Update the editFormState with the updated form state value
         */
        Object.assign(editFormState, {
          id: value.id,
          logo40: value.logo40,
          logo80: value.logo80,
          logo400: value.logo400,
          name: value.name,
          domain: authorizedDomains,
          includeSubdomain: value.includeSubdomain,
          email: value.supportEmail?.id,
          category: value.category,
          isPublic: value.isPublic,
          homepageUri: value.homepageUri,
          tosUri: value.tosUri,
          privacyPolicyUri: value.privacyPolicyUri,
          redirect: redirectUris,
          clientId: value.clientId,
          clientSecret: value.clientSecret,
          notificationSecret: value.notificationSecret,
        });
      }
    });

    // On Back Button
    const handleBack = async () => {
      await handleRefetchDeveloperAppList();
      // go back to dashboard
      router.push({ name: routeNames.applications });
    };

    // Remove App
    const handleOnRemoveAppSuccess = async () => {
      try {
        message.info(t("App Successfully Deleted."));
        router.push({ name: routeNames.applications });
      } catch (error) {
        console.log("Error on Configure App", error);
      }
    };

    return {
      t,
      config,
      activeKey,
      application,
      applicationState,
      BackIcon,
      handleBack,
      handleOnRemoveAppSuccess,
      ConfigureAppModalRef,
      ConfigureAppTabType,
      applicationLoading,
      editFormState,
    };
  },
});
