import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_InputOtp = _resolveComponent("InputOtp")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "trigger", { open: _ctx.showModal }),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      centered: true,
      title: _ctx.title,
      class: _normalizeClass(['confirm-otp-modal', _ctx.modalClass]),
      footer: null,
      "destroy-on-close": "",
      onCancel: _ctx.handleCancel
    }, {
      closeIcon: _withCtx(() => [
        _createVNode(_component_PlatformIcon, {
          name: "CloseIcon",
          class: "confirm-otp-modal__close-icon"
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_FormGroup, {
          ref: "formRef",
          class: "confirm-otp-modal__from-group flex",
          model: _ctx.formLoginState,
          layout: "inline",
          loading: _ctx.loading,
          onFinish: _ctx.handleConfirmChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_paragraph, { class: "m-0 mb-2 whitespace-pre-wrap" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.body), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              (!_ctx.hideOTPInput)
                ? (_openBlock(), _createBlock(_component_InputOtp, {
                    key: 0,
                    ref: "inputOtpRef",
                    value: _ctx.formLoginState.otpCode,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formLoginState.otpCode) = $event)),
                    class: "confirm-otp-modal__from-group-otp-input",
                    name: "otpCode",
                    disabled: _ctx.loading || _ctx.disabled,
                    onOnComplete: _ctx.handleConfirmChange
                  }, null, 8, ["value", "disabled", "onOnComplete"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                "html-type": "submit",
                type: "primary",
                class: "mr-2",
                loading: _ctx.loading,
                disabled: _ctx.disabled,
                danger: _ctx.type === _ctx.ConfirmModalTypes.danger
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                ]),
                _: 1
              }, 8, ["loading", "disabled", "danger"]),
              _createVNode(_component_a_button, {
                disabled: _ctx.loading || _ctx.disabled,
                onClick: _ctx.handleCloseModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["model", "loading", "onFinish"])
      ]),
      _: 1
    }, 8, ["visible", "title", "class", "onCancel"])
  ], 64))
}