
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import InputText from "@/shared/components/Forms/InputText.vue";
import { useVModel } from "vue-composable";
import {
  makeRequiredRule,
  makeUrlRule,
  makeURLDomainRule,
} from "@/shared/utils/validators/commonValidators";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";
import { ChannelType } from "../../../../../../__generated__/globalTypes";

export type URLRedirectType = {
  urlRedirect: string | null;
}[];

export default defineComponent({
  emits: ["remove-url"],
  props: {
    formArray: {
      type: Object as PropType<URLRedirectType[]>,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const formArrayModel = useVModel(props, "formArray");

    // Filter channel type domain here
    const { channelList, loading } = useCommunicationChannelList({
      isVerified: true,
      channelType: ChannelType.DOMAIN,
    });

    return {
      t,
      formArrayModel,
      channelList,
      linksLoading: computed(() => loading.value || props.loading),
    };
  },
  methods: { makeRequiredRule, makeUrlRule, makeURLDomainRule },
  components: {
    InputText,
  },
});
