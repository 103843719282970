import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardEmptyList = _resolveComponent("DashboardEmptyList")!
  const _component_DashboardAppList = _resolveComponent("DashboardAppList")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('For developers')
  }, {
    default: _withCtx(() => [
      (_ctx.isEmpty(_ctx.parsedAppList))
        ? (_openBlock(), _createBlock(_component_DashboardEmptyList, {
            key: 0,
            loading: _ctx.loading,
            onOnAddNew: _ctx.handleAddNewOrContinue
          }, null, 8, ["loading", "onOnAddNew"]))
        : (_openBlock(), _createBlock(_component_DashboardAppList, {
            key: 1,
            appList: _ctx.parsedAppList,
            onOnAddNew: _ctx.handleAddNewOrContinue,
            onOnConfigure: _ctx.handleConfigure
          }, null, 8, ["appList", "onOnAddNew", "onOnConfigure"]))
    ]),
    _: 1
  }, 8, ["title"]))
}